.editable-checklist {
	label {
		align-items: center;
		justify-content: flex-start;

		input[type="checkbox"] {
			margin-right: 5px;
		}
	}
}

.editableform {
	
	.form-group {
		flex-flow: column;
		align-items: flex-start;
	}
}