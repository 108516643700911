/*------------------------------------------------------------------
  [Wizard]
*/

//Custom Wizard Style
.block-wizard{
	padding:0;
}

.fuelux .wizard {
	box-shadow: none;
	border-radius: 0;
	-webkit-border-radius: 0;
	border: 0;
	background: theme-color("light");

  label.control-label{
    font-weight: 600;
  }

  .wizard-title{
    margin: 15px 0 20px;
  }

	> .actions{
		line-height: 48px;
    background: darken(theme-color("light"), 3%);
    padding: 0;
    font-size: 0;
    z-index: 1;

    .btn{
      position: relative;
      line-height: 48px;
      border-width: 0;
      border-radius: 0;
      vertical-align: top;
      padding: 0 14px;
      box-shadow: none;
      font-weight: $font-weight-regular;
      color: hsl(0, 0%, 58%);

      &:focus, &:active{
        box-shadow: none;
        border-width: 0;
      }

      &:hover{
        background-color: darken(theme-color("light"), 3%);
      }

      &:first-child{
        border-right: 1px solid #d4d4d4;
      }

      .icon{
        position: absolute;
        top: 12px;
        font-size: 25px;
        line-height: 1;
        color: hsl(0, 0%, 58%);
      }

      &.btn-prev{
        padding-left: 25px;

        .icon {
          left: 4px;
        }
      }

      &.btn-next{
        padding-right: 25px;

        .icon {
          right: 4px;
        }
      }
    }
	}

  > .steps-container {
    border-radius: 0;

    > ul.steps{

      > li{
        height: 48px;
        background-color: darken(theme-color("light"), 3%);
        
        .chevron{
          border-left-color: darken(theme-color("light"), 12%);

          &:before{
            border-left: 14px solid darken(theme-color("light"), 3%);
          }
        }
      
      }

      li{
        font-size: 15px;

        &:first-child{
          border-radius: 0;
        }

        &.active{
          background: theme-color("light");
          color: theme-color("primary");

          .chevron:before {
            -moz-transform: scale(.9999);
            border-left: 14px solid theme-color("light");
          }
        }

        &.complete{
          color: theme-color("light");
          background: theme-color("primary");

          .chevron{
            border-left-color: darken(theme-color("primary"), 8%);

            &:before{
              border-left: 14px solid theme-color("primary");
            }
          }

          &:hover{
            background: lighten(theme-color("primary"), 3%);

            .chevron{
              border-left-color: darken(theme-color("primary"), 6%);

              &:before{
                  border-left-color: lighten(theme-color("primary"), 3%);
              }
            }
          }
        }

        .chevron{
          -moz-transform: scale(.9999);
        }
      }
    }
  }

	> .actions{

		button{
			font-size: 12px;

			i{
				font-size: 11px;
				line-height: 17px;
			}

			&.btn-prev{

				i{
					margin-right: 3px;
				}
			}

			&.btn-next{

				i{
					margin-left: 3px;
				}
			}
		}
	}

	.step-content{
		padding: 20px 30px;
		clear: both;
		border-top: 1px solid #E2E2E2;
	}
}


@media (max-width: 767px) {
  .wizard-row .fuelux{
  	position: static;
  }
}