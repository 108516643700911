.table-filters-container {
	margin: 0;
	border-bottom: 1px solid darken($white, 10%);
}

.table-filters {
	padding: $table-filters-padding;
	display: flex;
	flex-direction: column;
}

.table-filter-title {
  flex: 0 0 auto;
  margin-bottom: 15px;
	font-size: $table-filter-title-font-size;
  text-transform: uppercase;
  font-weight: $font-weight-semi-bold;
  color: lighten($black, 47%);
}

#slider-value {
	font-size: 1.5rem;
	font-weight: $font-weight-regular;
}

.filter-container {
	padding: $table-container-filter-padding;
}