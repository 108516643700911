/*------------------------------------------------------------------
  [Nestable list]
*/
.dd {
	position: relative;
	display: block; 
	margin: 0; 
	padding: 0; 
	max-width: 46.1538rem; 
	list-style: none; 
	font-size: 1rem; 
	line-height: 1.53846rem;
}

.dd-list {
	display: block; 
	position: relative; 
	margin: 0; 
	padding: 0; 
	list-style: none;

	.dd-item, .dd3-item {

		.dd-handle {
			cursor: default;
			display: block; 
			margin: 0.384615rem 0; 
			padding: 0.538462rem 0.769231rem; 
			text-decoration: none; 
			border: 1px solid darken(theme-color("light"), 13%);
			background: theme-color("light");

			&:hover {
				color: theme-color("light"); 
				background: theme-color("primary"); 
				border-color: theme-color("primary");
			}
		}

		> button {
			display: block; 
			position: relative; 
			cursor: pointer; 
			float: left; 
			width: 1.92308rem; 
			height: 1.53846rem; 
			margin: 0.615385rem 0.153846rem; 
			padding: 0; 
			text-indent: 100%; 
			white-space: nowrap; 
			overflow: hidden; 
			border: 0; 
			background: 
			transparent; 
			font-size: 1.5rem;
			color: $gray;

			&:before {
				content: '+'; 
				display: block; 
				position: absolute; 
				width: 100%; 
				text-align: center; 
				text-indent: 0;
			}

			&[data-action="collapse"]:before {
			 content: '-';
			}
		}

		.dd3-content {
			display: block; 
			margin: 0.384615rem 0; 
			padding: 0.538462rem 0.769231rem 0.538462rem 3.07692rem;  
			text-decoration: none;
			background: none repeat scroll 0 0 theme-color("light");
			border: 1px solid darken(theme-color("light"), 13%);

			&:hover {
				background: theme-color("light");
			}
		} 

		.dd3-handle {
			position: absolute;
			margin: 0; 
			left: 0; 
			top: 0; 
			cursor: all-scroll; 
			width: 2.61538rem; 
			text-indent: 100%; 
			white-space: nowrap; 
			overflow: hidden;
			border: 1px solid theme-color("primary");
			background: theme-color("primary");
			height: 2.7rem;

			&:before {
				content: '='; 
				display: block; 
				position: absolute; 
				left: 0; 
				top: 0.384615rem; 
				width: 100%; 
				text-align: center; 
				text-indent: 0; 
				color: theme-color("light"); 
				font-size: 1.8rem;
				line-height: 1.84615rem;
				font-weight: normal;
			}

			&:hover {
				background: lighten(theme-color("primary"), 5%);
				border-color: lighten(theme-color("primary"), 5%);
			}
		}
	}

	.dd3-item {
		
		> button {
			margin-left: 2.69231rem;
		}
	}

	.dd-item, .dd-empty, .dd-placeholder {
		display: block; 
		position: relative; 
		margin: 0; 
		padding: 0;
		min-height: 1.53846rem; 
		font-size: 1rem; 
		line-height: 1.53846rem;
	}

	.dd-placeholder, .dd-empty {
		margin: 0.384615rem 0; 
		padding: 0; 
		min-height: 30px; 
		background: #FFF; 
		border: 1px dashed darken(theme-color("light"), 30%); 
		box-sizing: border-box; 
		-moz-box-sizing: border-box;
	}

	.dd-empty {
		border: 1px dashed #bbb; 
		min-height: 7.69231rem; 
		background-color: #e5e5e5;
		background-image: -webkit-linear-gradient(45deg, theme-color("light") 25%, transparent 25%, transparent 75%, theme-color("light") 75%,theme-color("light")),
		-webkit-linear-gradient(45deg, theme-color("light") 25%, transparent 25%, transparent 75%, theme-color("light") 75%, theme-color("light"));
		background-image: -moz-linear-gradient(45deg, theme-color("light") 25%, transparent 25%, transparent 75%, theme-color("light") 75%, theme-color("light")),
		-moz-linear-gradient(45deg, theme-color("light") 25%, transparent 25%, transparent 75%, theme-color("light") 75%, theme-color("light"));
		background-image: linear-gradient(45deg, theme-color("light") 25%, transparent 25%, transparent 75%, theme-color("light") 75%, theme-color("light")),
		linear-gradient(45deg, theme-color("light") 25%, transparent 25%, transparent 75%, theme-color("light") 75%, theme-color("light"));
		background-size: 4.61538rem 4.61538rem;
		background-position: 0 0, 2.30769rem 2.30769rem;
	}

	.dd-collapsed {

		.dd-list { 
			display: none; 
		}
	}

	&.dd-dragel {
		position: absolute; 
		pointer-events: none; 
		z-index: 9999;

		> .dd-item .dd-handle {
			margin-top: 0;
		}

		.dd-handle, .dd3-handle {
			-webkit-box-shadow: 0.153846rem 0.307692rem 0.461538rem 0 rgba(0,0,0,.1);
			box-shadow: 0.153846rem 0.307692rem 0.461538rem 0 rgba(0,0,0,.1);
		}
	}

	.dd-list {
		padding-left: 2.30769rem;
	}
}