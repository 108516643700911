/*------------------------------------------------------------------
  [Colors]
*/
.clr-primary {				background-color: theme-color("primary"); }
.clr-success {				background-color: theme-color("success"); }
.clr-info {						background-color: theme-color("info"); }
.clr-warning {				background-color: theme-color("warning"); }
.clr-danger {					background-color: theme-color("danger"); }
.clr-grey {						background-color: theme-color("grey"); }
.clr-dark {						background-color: theme-color("noir"); }
.clr-light {						background-color: theme-color("light"); }
.clr-black {						background-color: $black; }