.mai-forgot-password {

	@include phone {
		height: 100%;
	}

	.main-content {
		padding: 0;
	}
}

.form-forgot-password {
	.contact {
		text-align: center;
		font-size: 1rem;
	}
}