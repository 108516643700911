/*------------------------------------------------------------------
  [Code]
*/
pre {
	padding: 1.538rem;
	border: 0;
	border-radius: 0;
	background-color: darken(theme-color("light"), 2%);

	code {
		white-space: pre-wrap;
	}
}