// Buttons

// Default style
.btn {
	border: 1px solid transparent;

  &:active:focus,
  &.active:focus,
  &:focus {
  	outline: none;
  }

  &.dropdown-toggle:after {
  	display: none;
  }

  &.btn-outline-primary {
    @include button-outline-variant(theme-color("primary"));
  }
  &.btn-outline-light {
    @include button-outline-variant(theme-color("light"), theme-color("noir"));
  }
  &.btn-outline-info {
    @include button-outline-variant(theme-color("info"));
  }
  &.btn-outline-success {
    @include button-outline-variant(theme-color("success"));
  }
  &.btn-outline-warning {
    @include button-outline-variant(theme-color("warning"));
  }
  &.btn-outline-danger {
    @include button-outline-variant(theme-color("danger"));
  }
}

// Icons inside buttons
.btn {

  .icon {
    position: relative;
    top: -1px;
    font-size: 1.154rem;
    line-height: 0.6;
    vertical-align: middle;

    &.icon-left {
      margin-right: $btn-icon-margin;
    }

    &.icon-right  {
      margin-left: $btn-icon-margin;
    }
  }

  &.btn-lg .icon {
  	font-size: 1.231rem;
  }

  &.btn-sm .icon {
  	font-size: 1.077rem;
  }

  &.btn-xs .icon  {
    font-size: 1rem;
  }

  .icon-dropdown {
  	font-size: 1.538rem;
    line-height: 0.3;
    vertical-align: middle;
    display: inline-block;
    width: 1rem;
  }
}

// Dark button
.btn-dark {
  @include button-variant($btn-dark-bg, $btn-dark-border);
}

//
// Alternate buttons
//

.btn-primary {
  @include mai-button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}
.btn-secondary {
  @include mai-button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
}
.btn-info {
  @include mai-button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}
.btn-success {
  @include mai-button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}
.btn-warning {
  @include mai-button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}
.btn-danger {
  @include mai-button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}
.btn-dark {
  @include mai-button-variant($btn-dark-color, $btn-dark-bg, $btn-dark-border);
}

//
// Button Sizes
//

.btn-xs {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-xs-font-size, $btn-line-height, $btn-border-radius);
}

.btn-sm {
	font-size: $btn-sm-font-size;
}

.btn-lg {
	font-size: $btn-lg-font-size;
}

// Button with rounded borders
.btn-rounded {
	border-radius: $input-btn-padding-y + $font-size-base;

	&.btn-xs {
		border-radius: $btn-padding-y-xs + $btn-xs-font-size;
	}

	&.btn-sm {
		border-radius: $btn-padding-y-sm + $btn-sm-font-size;
	}

	&.btn-lg {
		border-radius: $btn-padding-y-lg + $btn-lg-font-size;
	}
}

//Big buttons
.btn-big{
	padding: .7rem 1rem;
	text-align: center;
	min-width: 70px;

	> .icon{
		display: block;
		font-size: 2rem;
		line-height: 1;
		margin-bottom: .3rem;
	}
}

// Social buttons
.btn-social {
	min-width: 2.9rem;

	.icon {
		font-size: 1.1rem;
	}

  &.btn-xs {
    min-width: 2.3rem;
  }
}

//Social buttons colors
.btn-facebook{ .icon{ color: $facebook; } @include button-icon-active-color($facebook) }
.btn-twitter{ .icon{ color: $twitter; } @include button-icon-active-color($twitter) }
.btn-google-plus{ .icon{ color: $google-plus; } @include button-icon-active-color($google-plus) }
.btn-dribbble{ .icon{ color: $dribbble; } @include button-icon-active-color($dribbble) }
.btn-flickr{ .icon{ color: $flickr; } @include button-icon-active-color($flickr) }
.btn-linkedin{ .icon{ color: $linkedin; } @include button-icon-active-color($linkedin) }
.btn-youtube{ .icon{ color: $youtube; } @include button-icon-active-color($youtube) }
.btn-pinterest{ .icon{ color: $pinterest; } @include button-icon-active-color($pinterest) }
.btn-github{ .icon{ color: $github; } @include button-icon-active-color($github) }
.btn-tumblr{ .icon{ color: $tumblr; } @include button-icon-active-color($tumblr) }
.btn-dropbox{ .icon{ color: $dropbox; } @include button-icon-active-color($dropbox) }
.btn-behance{ .icon{ color: $behance; } @include button-icon-active-color($behance) }

.btn-color.btn-facebook{ .icon{ color: theme-color("light"); } @include social-button-variant(theme-color("light"), $facebook, $facebook); }
.btn-color.btn-twitter{ .icon{ color: theme-color("light"); } @include social-button-variant(theme-color("light"), $twitter, $twitter);  }
.btn-color.btn-google-plus{ .icon{ color: theme-color("light"); } @include social-button-variant(theme-color("light"), $google-plus, $google-plus); }
.btn-color.btn-dribbble{ .icon{ color: theme-color("light"); } @include social-button-variant(theme-color("light"), $dribbble, $dribbble); }
.btn-color.btn-flickr{ .icon{ color: theme-color("light"); } @include social-button-variant(theme-color("light"), $flickr, $flickr); }
.btn-color.btn-linkedin{ .icon{ color: theme-color("light"); } @include social-button-variant(theme-color("light"), $linkedin, $linkedin); }
.btn-color.btn-youtube{ .icon{ color: theme-color("light"); } @include social-button-variant(theme-color("light"), $youtube, $youtube); }
.btn-color.btn-pinterest{ .icon{ color: theme-color("light"); } @include social-button-variant(theme-color("light"), $pinterest, $pinterest); }
.btn-color.btn-github{ .icon{ color: theme-color("light"); } @include social-button-variant(theme-color("light"), $github, $github); }
.btn-color.btn-tumblr{ .icon{ color: theme-color("light"); } @include social-button-variant(theme-color("light"), $tumblr, $tumblr); }
.btn-color.btn-dropbox{ .icon{ color: theme-color("light"); } @include social-button-variant(theme-color("light"), $dropbox, $dropbox); }
.btn-color.btn-behance{ .icon{ color: theme-color("light"); } @include social-button-variant(theme-color("light"), $behance, $behance); }

// Buton spacer helpers
.btn-hspace {
	margin-right: 4px;
}

.btn-vspace {
	margin-bottom: 5px;
}

.btn-space {
	margin-right: 4px;
	margin-bottom: 5px;
}

//
// Link buttons
//

.btn-link {
  font-weight: $font-weight-bold;
  color: $btn-link-color;

  @include hover-focus {
    color: $btn-link-hover-color;
    text-decoration: none;
  }

  &:active,
  &.active,
  .show > &.dropdown-toggle {
    background-color: rgba(0,0,0,0.07);
  }
}

.btn-link-secondary {
  @include button-link-variant($btn-link-color);
}

.btn-link-primary {
  @include button-link-variant(theme-color("primary"));
}

.btn-link-success {
  @include button-link-variant(theme-color("success"));
}

.btn-link-info {
  @include button-link-variant(theme-color("info"));
}

.btn-link-warning {
  @include button-link-variant(theme-color("warning"));
}

.btn-link-danger {
  @include button-link-variant(theme-color("danger"));
}