// Heading colored panel
@mixin panel-heading-color ($color, $set-defaults: false) {
  background-color: $color;

  @if $set-defaults != true {
    color: theme-color("light");
  }

  @if $set-defaults != true {
    .panel-subtitle {
      color: theme-color("light");
    }
  }

  @if $set-defaults != true {
    .tools {
      .icon {
        color:theme-color("light");

        &:hover {
          color: darken($color, 6%);
        }
      }
    }
  }
}

// Full colored panel
@mixin panel-full-color($color, $set-defaults: false) {
  background-color: $color;

  @if $set-defaults != true {
    color: theme-color("light");
  }

  @if $set-defaults != true {
    .panel-heading {
      color: theme-color("light");
    }
  }

  @if $set-defaults != true {
    .panel-subtitle {
      color: theme-color("light");
    }
  }

  @if $set-defaults != true {
    .tools {
      .icon {
        color:theme-color("light");

        &:hover {
          color: darken($color, 6%);
        }
      }
    }
  }
}