// Checkboxes and radios

.custom-control {
  min-height: $custom-control-indicator-size;
  min-width: $custom-control-indicator-size;
}

.custom-control-input {

  &:checked ~ .custom-control-indicator {
    border-color: theme-color("primary");
  }

  &:checked ~ .custom-control-color {
		background-color: theme-color("primary");
		color: theme-color("light");
	}
}

// Select

.custom-select {
	@include border-radius(0);
}

// Checkboxes

.custom-checkbox {
  .custom-control-input:checked ~ .custom-control-indicator {
    &:before {
    	content: "\e6ca";
    }
  }
}

// Radios

.custom-radio {

  .custom-control-input:checked ~ .custom-control-indicator {
  	&:before {
    	content: "\e6ce";
    	right: 0;
    	font-size: 14px;
    	line-height: 1;
    	vertical-align: initial;
  	}
  }
}

// Custom indicator

.custom-control-indicator {
	border: 2px solid $input-border-color;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;

	&:before {
		position: relative;
		content: ""; 
		display: inline-block;
		font-size: $input-check-component-size - 2px;
		font-family: $icon-font;
		font-weight: normal;
		line-height: 1;
	} 
}

// Icon radio
.custom-radio-icon {
	margin: 0;
	padding: 0;
	width: $radio-icon-component-size;
	height: $radio-icon-component-size;

	input[type="radio"] {
		margin: 0;
		display: none;
		width: $radio-icon-component-size;
	}

	.custom-control-indicator {
		cursor: pointer;
		width: $radio-icon-component-size;
		height: $radio-icon-component-size;
		line-height: 1;
		border: 2px solid $radio-icon-component-border;
		border-radius: 50%;
		font-size: $radio-icon-component-icon-size;
		color: $radio-icon-component-color;
		background-color: theme-color("light");

		&:hover {
			border-color: darken($radio-icon-component-border, 10%);
		}

		.custom-control-description {
			vertical-align: middle;
		}
	}

	// Checked
	input[type="radio"]:checked {

		& + .custom-control-indicator {
			border-color: theme-color("primary");
			color: theme-color("primary");
			background-color: theme-color("light");

			&:before {
				display: none;
			}
		}
	}

	input[type="radio"]:focus {
		& + .custom-control-indicator {
			box-shadow: none;
		}
	}	

	input[type="radio"]:active {
		& + .custom-control-indicator {
			color: $radio-icon-component-color;
			background-color: theme-color("light");
		}
	}	

	// Inline style
	&.inline {
		display: inline-flex;

		& + & {
			margin-left: 18px;
		}
	}
}

//Form validation states
.form-control-success {
  background-image: $form-icon-success;
}

.form-control-danger {
  background-image: $form-icon-danger;
}

@include custom-form-validation-state("valid", theme-color("success"));
@include custom-form-validation-state("invalid", theme-color("danger"));

// Sizing
.custom-control-sm {
	min-width: 1.38462rem;
	min-height: 1.38462rem;

	.custom-control-indicator {
		width: 1.38462rem;
		height: 1.38462rem;

		&:before {
			font-size: 15px;
			right: 0;
			line-height: 1;
			vertical-align: top;
		}
	}
}