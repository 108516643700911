/*------------------------------------------------------------------
  [Error pages]
*/
.mai-error {
	
	.error-container {
		text-align: center;
		margin-top: 50px;
	}

	.error-image svg {
		position: relative;
		max-width: 255px;
		right: -20px;

		.cls-1 {
			fill: theme-color("primary");
		}

		.cls-2{
			fill: darken(theme-color("primary"), 7%);
		}

		@include phone {
			max-width: 123px;
			right: -12px;
		}
	}

	.error-number {
		display: block;
		font-size: 14.0769rem;
		font-weight: $font-weight-bold;
		line-height: 1;
		margin-bottom: 30px;
		max-height: 240px;

		&:after {
			padding-top: 0.25%;
			display: block;
			content: '';
		}

		@include phone {
			padding: 20px 0;
			font-size: 90px;
			margin-bottom: 5px;
			line-height: 100px;
		}
	}

	.error-description {
		font-size: 2.30769rem;
		line-height: 1;
		margin-bottom: 30px;

		@include phone {
			font-size: 1.46154rem;
		}
	}

	.error-goback-text {
		font-size: 1.69231rem;
		font-weight: $font-weight-light;
		margin-bottom: 30px;
		margin-top: 15px;

		@include phone {
			font-size: 1.15385rem;
		}
	}

	.error-goback-button {
		margin-bottom: 30px;
	}

	.footer {
		margin-bottom: 30px;
	}
}

/*------------------------------------------------------------------
  [Page 404]
*/
.mai-error-404 {

	.error-container {

		.error-number {

			@include phone {
				padding: 0;
				font-size: 70px;
			}

			@media (max-width: 340px) {
				font-size: 50px;
				line-height: 70px;
			}

			@media (max-width: 295px) {
				font-size: 40px;
			}
		}
	}
}