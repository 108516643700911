.mai-splash-screen {

	.main-content {
		max-width: 100%;
		width: 100%;
	}
}

.splash-container {
	padding-top: 55px;
	width: 800px;
	margin: 0 auto;

	@include laptop {
		width: 700px;
	}

	@include tablet {
		padding: 20px;
		width: 400px;
		display: flex;
	}

	@include phone {
		padding: 0 20px;
		width: 100%;		
		background-color: lighten($black, 21%);
	}
	
	.user-message {
		display: flex;
		flex-direction: column;
		background-color: theme-color("primary");
		color: theme-color("light");
		padding: 155px 30px 40px;

		@include tablet {
			display: none;
		}
	}

	.splash-message {
		flex: 1 1 auto;
		font-size: 2rem;
		line-height: 1.3;
		font-weight: $font-weight-light;
	}

	.alternative-message {
		color: desaturate( darken(theme-color("primary"), 15%), 20% );

		a {
			color: theme-color("light");
			opacity: .8;

			@include hover {
				opacity: 1;
			}
		}
	}

	.form-message {
		display: flex;
		flex-direction: column;
		padding: 60px 30px 40px;
		background-color: lighten($black, 21%);
		color: darken(theme-color("light"), 17%);

		@include phone {
			padding: 60px 0 40px;
		}

		.out-links a{
			text-align: center;
			display: block;
			margin-top: 3rem;
			color: darken(theme-color("light"), 33%);

			&:hover{
				color: theme-color("light");
			}
		}
	}

	.logo-img {
		margin: 0 auto;
		display: block;
	}

	.splash-description {
		color: theme-color("light");
		font-size: 1.53846rem;
		font-weight: $font-weight-light;
		display: block;
		margin: 0 auto;
	}

	.form-group {
		margin: 0;
	}

	.input-group {
		border-bottom: 2px solid lighten(theme-color("dark"), 5%);
		padding: 6px 0;

		.input-group-addon {
			border: 0;
			background-color: transparent;

			.icon {
				font-size: 20px;
				color: theme-color("primary");
			}
		}

		.form-control {
			border: 0;
			background-color: transparent;
			box-shadow: none;
			color: lighten(theme-color("dark"), 40%);
			padding: 0;
			font-size: 14px;
			font-weight: 500;
			height: 36px;
		}
	}
}