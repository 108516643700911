.widget-calendar {
	padding: 0;
}

.cal-calendar{
	position: relative;
	padding: 40px;
	background-color: theme-color("noir");

	@include laptop {
		padding: 20px 10px;
	}

	&:after {
		position: absolute;
		display: block;
		top: 135px;
		left: 0;
		background-color: theme-color("primary");
		height: 46px;
		width: 100%;
		content: " ";
		z-index: 0;

		@include laptop {
			top: 75px;
		}
	}

	.ui-datepicker{
		position: relative;
		z-index: 1;

		.ui-datepicker-header{
		  	margin-bottom: 4.61538rem;

		  	@include laptop {
		  		margin-bottom: 2.31538rem;
		  	}

		  	.ui-datepicker-title{
		  		color: theme-color("light");
		  		cursor: default;
		    	line-height: 1;
		    	margin: 0 auto;
		    	text-align: center;
		    	font-weight: $font-weight-light;
		    	font-size: 2.76923rem;

		    	@include laptop {
		    		font-size: 1.8rem;
		    	}    
		  	}

		  	.ui-datepicker-prev, .ui-datepicker-next {
		  		height: 1.8em;
		    	position: absolute;
		    	top: -5px;
		    	width: 2.76923rem;
		    	color: theme-color("light");
		    	font-size: 2.76923rem;

		    	@include laptop {
		    		font-size: 1.69231rem;
		    	}

		    	@include phone {
		    		font-size: 1.69231rem;
		    	}

		    	span{
		    		overflow: hidden;
				  	display: block;
				  	text-indent: -99999px;

				  	&:after{
				  		cursor: pointer;
					  	content: "\e686";
					  	font-family: $icon-font;
					  	display: block;
					 	position: absolute;
					 	width: 16px;
					  	height: 16px;
					  	top: 0;
					  	text-indent: 0;
				  	}
		    	}
		  	}

		  	.ui-datepicker-prev{
		  		left: 0;
		  	}

		  	.ui-datepicker-next{
		  		right: 0;

		  		span{
		  			&:after{
		  				content: "\e684";
		  			}
		  		}
		  	}
		}

		table.ui-datepicker-calendar{
			width: 100%;

			th{
				border: 0;
				padding: .769231rem .230769rem 1.30769rem;
				text-align: center;
				font-weight: $font-weight-light;
				color: theme-color("light");
				span{
					font-size: 1.30769rem;
				}
			}

			tr{

				td{
					border: 0;
					padding: .230769rem;
					text-align: center;

					a{
						display: inline-block;
				    	padding: .615385rem .538462rem;
				    	text-align: center;
				    	text-decoration: none;
				    	margin-top: .769231rem;
				    	color: theme-color("light");
				    	width: 33px;
				    	height: 33px;
				    	line-height: 1;
				    	font-size: 1.30769rem;

					   &:hover {
					   	color: theme-color("primary");
					   }

				    	&.ui-state-active, &.ui-state-highlight{
					    	background-color: theme-color("primary");
					    	color: theme-color("noir");
					    	border-radius: 50%;
				    	}

					   //Curent day style
					   &.ui-state-highlight{
					    	background-color: theme-color("primary");
					    	color: theme-color("light");
					   }
					}
				}
			}
		}
	}
}

.add-note {
	display: block;
	text-align: center;
	color: theme-color("light");
	font-size: 1.53846rem;
	font-weight: $font-weight-light;
	line-height: 1;
	margin-top: 2.30769rem;

	@include laptop {
		font-size: 1.3rem;
	}

	&:hover {
		color: theme-color("light");
	}

	.icon {
		color: theme-color("primary");
		font-size: 2rem;
		margin-right: 5px;
		vertical-align: middle;
		line-height: 0.2;
	}
}

.cal-notes{
	background-color: theme-color("light");
	padding: 30px;

	span{
		display: block;

		&.day{
			font-size: 2.76923rem;
			font-weight: 300;
		}

		&.date{
			font-size: 15px;
			font-weight: 300;
			color: theme-color("primary");
		}

		&.title{
			font-size: 1.38462rem;
			border-bottom: 1px solid darken(theme-color("light"), 15%);
			margin-top: 1.38462rem;
			padding-bottom: .153846rem;
		}
	}

	ul{
		padding: 0;
		list-style: none;
		padding-top: 20px;

		li{
			padding-bottom: 10px;
			font-size: $paragraph-size;

			span{
				display: inline-block;

				&.hour{
					float: right;
					color: theme-color("primary");
				}

				&.event-name{
					font-size: $paragraph-size;
				}
			}

			&:last-child{
				padding-bottom: 0;
			}
		}
	}
}

@include phone{
	//display: block;

	.cal-notes, .cal-calendar{
		display: block;
	}

	.cal-notes{
		width: 100%;
	}
}