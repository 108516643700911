.mai-sign-up {

	@include phone {
		height: 100%;
	}

	.main-content {
		padding: 0;
	}
}

.sign-up-form {

	.form-group.inline {

		[class*="col-"] {
			padding: 0;
		}
	}

	.sign-up-submit {
		padding: 1.53846rem 0 1.15385rem;
	}

	.title {
		font-size: 15px;
		color: darken(theme-color("light"), 15%);
		text-align: center;
	}

	.social-signup {

		[class*="col-"] {
			padding: 0 7px;

			&:first-child{
				padding-left: 0;
			}

			&:last-child{
				padding-right: 0;
			}
		}
	}

	.conditions {
		text-align: center;
		font-size: 1rem;
	}
}