// Theme

.group-header{
  $primary-color : #2cc185;

  .group-box{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    padding: 20px;
    flex-wrap: wrap;
    justify-content: space-between;

    .group-box-info{
      .group-box-name{
        display:block;
        font-size: 30px;

      }
      .group-box-address{
        display:block;
        margin-top: 3px;
        font-size: 12px;

        .icon {
          color: $primary-color ;
        }
      }
    }

    .group-box-stats{
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      .group-box-stat{
        border-right: 1px solid #d9d9d9;
        min-width: 130px;
        text-align: center;
        display: block;
        padding: 10px 0;

        .group-box-stat-counter{
          font-size: 2.3rem;
          color: $primary-color;
          line-height: 1;
          margin-bottom: 0.5rem;
          text-align: center;
          display: block;
        }

        .group-box-stat-title{
          display: block;
          text-align: center;
          font-size: 1.2rem;
          text-transform: uppercase;
          line-height: 1;
        }

      }

      .group-box-stat:last-child {
        border-right-width: 0;
      }
    }
    @media only screen and (max-width: 580px) {
      .group-box-stats{
        .group-box-stat{
          padding: 5px 0;
          min-width: 60px;
          .group-box-stat-counter{
            font-size: 1rem;
          }
          .group-box-stat-title{
            font-size: 0.5rem;
          }
        }
      }
    }
  }

  .group-menu{
    background-color: $primary-color;
    padding: 20px;

    ul{
      margin-bottom: 0px;
      padding-left: 0px;

      li{
        display: inline;
        list-style: none;
        margin-right: 5px;

        a{
          color: #fff;
          font-size: 14px;
          padding: 6px 12px;
          border-radius: 4px;
        }

        a:hover{
          background-color: lighten($primary-color , 5%);
        }
      }

      li:last-child {
        float:right;
      }

      li a.router-link-exact-active{
        background-color: #fff;
        color: $primary-color ;
      }

      @media only screen and (max-width: 750px) {
        li{
          display: list-item;
          margin-top: 3px;
          text-align: center;
        }

        li:last-child {
          float:none;
        }

        li a.router-link-exact-active{
          background-color: transparent;
          color: #fff ;
          font-weight: 800;
        }
      }
    }
  }

}

.filters {
  padding: 20px 20px 0px 20px;
  .filters-title{
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    color: #787878;
  }

  .filters-content{
    .p-2{
      padding-left: 0px !important;
    }
  }
}

/**
// Range Slider
 */

// Base Colors
$shade-10: #2cc185 !default;
$shade-1: #d7dcdf !default;
$shade-0: #fff !default;
$teal: #1abc9c !default;


.range-slider {
  margin: 60px 0 0 0%;
}


// Range Slider
$range-width: 100% !default;

$range-handle-color: $shade-10 !default;
$range-handle-color-hover: $teal !default;
$range-handle-size: 20px !default;

$range-track-color: $shade-1 !default;
$range-track-height: 5px !default;

.range-slider {
  width: $range-width;
}

.range-slider {
  -webkit-appearance: none;
  width: 100%;
  height: $range-track-height;
  border-radius: 5px;
  background: $range-track-color;
  outline: none;
  padding: 0;
  margin: 0;

  // Range Handle
  &::-webkit-slider-thumb {
    appearance: none;
    width: $range-handle-size;
    height: $range-handle-size;
    border-radius: 50%;
    background: $range-handle-color;
    cursor: pointer;
    transition: background .15s ease-in-out;

    &:hover {
      background: $range-handle-color-hover;
    }
  }

  &:active::-webkit-slider-thumb {
    background: $range-handle-color-hover;
  }

  &::-moz-range-thumb {
    width: $range-handle-size;
    height: $range-handle-size;
    border: 0;
    border-radius: 50%;
    background: $range-handle-color;
    cursor: pointer;
    transition: background .15s ease-in-out;

    &:hover {
      background: $range-handle-color-hover;
    }
  }

  &:active::-moz-range-thumb {
    background: $range-handle-color-hover;
  }

  // Focus state
  &:focus {

    &::-webkit-slider-thumb {
      box-shadow: 0 0 0 3px $shade-0,
      0 0 0 6px $teal;
    }
  }
}

// Firefox Overrides
::-moz-range-track {
  background: $range-track-color;
  border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}