.mai-mega-dropdown {
	position: static;
}

.mai-mega-menu.dropdown-menu {
	padding: $mega-menu-padding;
	margin-top: 0;
	width: 100%;
	border-width: 0;
	box-shadow: $mega-menu-box-shadow;
 	border-top-right-radius: 0;
 	border-top-left-radius: 0;
 	z-index: $mega-menu-zindex;

 	@include tablet {
 		.mai-nav-tabs-sub & {
	 		padding: 0;
	 		background-color: lighten($sub-header-mobile-bg, 1.2%);
 		}
 	}
}

.mai-mega-menu-row {
	display: flex;
  flex-wrap: wrap;
  margin-right: ($grid-gutter-width / -1.5);
  margin-left: ($grid-gutter-width / -1.5);

	@include tablet {
		flex-direction: column;
		margin: 0;
		padding: 0;
	}
}

.mai-mega-menu-column {
	position: relative;
	padding-top: $mega-menu-column-vertical-spacing;
	padding-bottom: $mega-menu-column-vertical-spacing;
	width: 100%;
  min-height: 1px;
  padding-right: ($grid-gutter-width / 1.5);
  padding-left: ($grid-gutter-width / 1.5);

	@include make-col(3);
	border-right: 1px solid darken(theme-color("light"), 5%);

	@include tablet {
		max-width: 100%;
		border-right-width: 0;
		padding: 0;
	}

	&:first-child {
		padding-left: 40px;

		@include tablet {
			padding: 0;
		}
	}

	&:last-child {
		border-right-width: 0;
	}
}

.mai-mega-menu-section {
	margin: $mega-menu-section-margin;
	
	@include tablet {
		margin: 0;		

		&.parent {
			> .nav-link {
				color: darken(theme-color("light"), 37%);
				
				&:after {
					position: absolute;
					content: $arrow-down-code;
					font-family: $icon-font;
					font-size: 1.9rem;
					vertical-align: middle;
					top: 0;
					right: 15px;
				}
			}
		}

		&.open {
			> .nav-link {
	    	color: $link-color;

	    	&:after {
	    		content: $arrow-up-code;
	    	}
	    }
		}
	}

	> .nav-link {
		color: theme-color("primary");
		font-size: 1.231rem;
		font-weight: 400;

		.navbar .navbar-nav & {
			padding: $mega-menu-section-padding;

			@include tablet {
				padding: 0 17px 0 ($sub-nav-item-padding + 30px);
			}
		}

		@include tablet {
			position: relative;
	    font-size: 1rem;
	    line-height: $sub-nav-height * 0.72;
	    color: darken(theme-color("light"), 37%);
		}

		.icon {
			font-size: 1.385rem;
			line-height: 1.1;
			vertical-align: middle;
			margin-right: 6px;
		}
	}
}

.mai-mega-menu-sub-items {

	@include tablet {
		display: none;
		padding: $sub-nav-mobile-vertical-padding 0;

		.mai-mega-menu-section.open & {
			display: block;
		}
	}

	.dropdown-item {
		font-size: 1.077rem;
		color: $sub-nav-dropdown-item-color;

		.navbar .navbar-nav & {
			padding: $mega-menu-item-padding;

			&:hover,
			&.active {
				background-color: transparent;
				color: theme-color("primary");
			}

			&:focus {
				background-color: transparent;
			}
		}

		@include tablet {
			.mai-nav-tabs-sub .dropdown-menu & {
				padding: $dropdown-item-padding-y $dropdown-item-padding-x $dropdown-item-padding-y ($sub-nav-item-padding + 52px);
				font-size: 1rem;
			}
		}
	}
}