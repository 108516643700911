// @import "node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone";
.datepicker {

  table tr {
    
    td,
    th {
      border-radius: 50%;
    }
  }

  &-inline {
    width: 220px;
  }

  direction: ltr;

  &-rtl {
    direction: rtl;

    table tr td span {
      float: right;
    }
  }

  &-dropdown {
    top: 0;
    left: 0;
    min-width: 160px;
    padding: 10px;
    z-index: $zindex-dropdown !important;

    &:before {
      content: '';
      display: inline-block;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid $gray-light;
      border-top: 0;
      border-bottom-color: rgba(0, 0, 0, 0.1);
      position: absolute;
    }

    &:after {
      content: '';
      display: inline-block;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid $white;
      border-top: 0;
      position: absolute;
    }

    &.datepicker-orient-left:before {
      left: 6px;
    }

    &.datepicker-orient-left:after {
      left: 7px;
    }

    &.datepicker-orient-right:before {
      right: 6px;
    }

    &.datepicker-orient-right:after {
      right: 7px;
    }

    &.datepicker-orient-bottom:before {
      top: -7px;
    }

    &.datepicker-orient-bottom:after {
      top: -6px;
    }

    &.datepicker-orient-top:before {
      bottom: -7px;
      border-bottom: 0;
      border-top: 7px solid rgba(0, 0, 0, 0.1);
    }

    &.datepicker-orient-top:after {
      bottom: -6px;
      border-bottom: 0;
      border-top: 6px solid $white;
    }
  }

  table {
    margin: 0;
    user-select: none;
    border-collapse: separate;
  }

  table thead tr th {
    padding: 10px 4px 8px;
    font-weight: $font-weight-regular;
    color: lighten($black, 44%);
  }

  td, th {
    text-align: center;
    width: 2.5rem;
    height: 2.5rem;
    color: darken(theme-color("light"), 47%);

    @include border-radius($input-border-radius);

    border: none;
  }

  // Inline display inside a table presents some problems with
  // border and background colors.
  .table-striped & table tr {
    td, th {
      background-color: transparent;
    }
  }

  table tr td {
    padding: 0 8px;
    line-height: 1.3;
    height: 33px;
    width: 33px;

    &.day:hover,
    &.day.focused {
      background: $gray-lighter;
      cursor: pointer;
    }

    &.old,
    &.new {
      color: darken(theme-color("light"), 22%);
    }

    &.disabled,
    &.disabled:hover {
      background: none;
      color: darken(theme-color("light"), 22%);
      cursor: default;
    }

    &.highlighted {
      background: $btn-info-bg;
      border-radius: 0;
    }

    &.today,
    &.today:hover,
    &.today.disabled,
    &.today.disabled:hover {
      background-color: darken($white, 4%);
      color: darken(theme-color("light"), 47%);
    }

    &.today:hover:hover {
      color: darken(theme-color("light"), 47%);
    }

    &.today.active:hover {
      color: #fff;
    }

    &.range,
    &.range:hover,
    &.range.disabled,
    &.range.disabled:hover {
      background: $gray-lighter;

      @include border-radius($input-border-radius);
    }

    &.range.today,
    &.range.today:hover,
    &.range.today.disabled,
    &.range.today.disabled:hover {
      $datepicker-range-today-bg: mix($orange, $gray-lighter, 50%);

      @include button-variant($btn-warning-color, $datepicker-range-today-bg, $btn-warning-border);
      @include border-radius($input-border-radius);
    }

    &.selected,
    &.selected:hover,
    &.selected.disabled,
    &.selected.disabled:hover {
      @include button-variant(lighten($gray-light, 10), darken($gray-light, 10), darken($gray-light, 20));

      color: #fff;
      text-shadow: none;
    }

    &.active,
    &.active.active,
    &.active.active:hover,
    &.active.active.disabled,
    &.active.active.disabled:hover {
      background-color: theme-color("primary");
      color: $white;
      border-width: 0;
      color: #fff;
      text-shadow: none;
    }

    span {
      display: block;
      width: 23%;
      height: 48px;
      line-height: 48px;
      float: left;
      margin: 1%;
      cursor: pointer;
      border-radius: 3px;

      &:hover,
      &.focused {
        background: $gray-lighter;
      }

      &.disabled,
      &.disabled:hover {
        background: none;
        color: $gray-light;
        cursor: default;
      }

      &.active,
      &.active.active,
      &.active.active:hover,
      &.active:hover.active:hover,
      &.active.active.disabled,
      &.active.active.disabled:hover {
        background-color: theme-color("primary");
        color: $white;
        border-width: 0;
        color: #fff;
        text-shadow: none;
      }

      &.old,
      &.new {
        color: darken(theme-color("light"), 22%);
      }
    }
  }

  .datepicker-switch {
    width: 145px;
  }

  .datepicker-switch,
  tfoot tr th {
    cursor: pointer;

    &:hover {
      color: lighten($black, 44%);
      background-color: transparent;
    }
  }

  .prev,
  .next {
    cursor: pointer;

    &:hover {
      color: theme-color("primary");
      background-color: transparent;
    }
  }

  // Basic styling for calendar-week cells
  .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
  }
}

.input-append,
.input-prepend {
  &.date .add-on {
    cursor: pointer;

    i {
      margin-top: 3px;
    }
  }
}

.input-daterange {
  input {
    text-align: center;
  }

  input:first-child {
    @include border-left-radius($input-border-radius);
  }

  input:last-child {
    @include border-right-radius($input-border-radius);
  }

  .add-on {
    display: inline-block;
    width: auto;
    min-width: 16px;
    height: $line-height-base;
    padding: 4px 5px;
    font-weight: normal;
    line-height: $line-height-base;
    text-align: center;
    text-shadow: 0 1px 0 $white;
    vertical-align: middle;
    background-color: $gray-lighter;
    border: 1px solid #ccc;
    margin-left: -5px;
    margin-right: -5px;
  }
}