//
// Textual form controls
//

.form-control {
	font-size: $paragraph-size;
  //padding: ($input-btn-padding-y + .113077) ($input-btn-padding-x + .5378);
  padding: $input-padding-y $input-padding-x;
}

.form-control[multiple] {
  padding: 0;

  option {
    padding: 14px 13px;
  }
}

textarea {

	&.form-control {
		background-color: $textarea-bg;

		&:focus {
			background-color: $textarea-bg;
		}
	}
}

// File input

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  & + label {
    padding: $input-btn-padding-y $input-btn-padding-x;
    display: inline-block;
    cursor: pointer;

    i {
      margin-right: 10px;
    }
  }
}

// Form control sizing
//

.form-control-xs {
  //padding: ($input-btn-padding-y - .425) ($input-btn-padding-x + .5378);
  padding: $input-padding-y-xs $input-padding-x-xs;
  font-size: 1rem;
}

select.form-control-xs {
  &:not([size]):not([multiple]) {
    height: $input-height-xs;
  }
}

.form-control-sm {
  font-size: 1rem;
  //padding: ($input-btn-padding-y-sm - .076915) ($input-btn-padding-x-sm + .5378);
  padding: $input-padding-y-sm $input-padding-x-sm;
}

.form-control-lg {
  font-size: 1.230rem;
  //padding: ($input-btn-padding-y-lg + .23075) ($input-btn-padding-x-lg + .5378);
  padding: $input-padding-y-lg $input-padding-x-lg;
}

// Form groups
.form-group {

  &.row {

    .col-form-label {

      .form-horizontal & {
        text-align: right;
      }
    }
  }
}

// Custom select
.custom-select {
  line-height: 1.2;
  padding: $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding) $custom-select-padding-y $custom-select-padding-x;
  -moz-appearance: none;
  -webkit-appearance: none;
}


// Form control states
.form-control-success,
.form-control-danger {
  padding-right: ($input-btn-padding-x * 3);
  background-repeat: no-repeat;
  background-position: center right ($input-height / 4);
  background-size: ($input-height / 2) ($input-height / 2);
}