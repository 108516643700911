/*------------------------------------------------------------------
  [Full calendar]
*/
.full-calendar {

	.fc-toolbar {
		padding-top: 25px;
		margin-bottom: 30px;
	}

	.fc-view-container {
		
		.fc-event-container {

			.fc-event {
			  background: theme-color("primary");
			  border-color: darken(theme-color("primary"), 8%);
			  color: theme-color("light");
			  border-radius: 0;
			  font-size: .923077rem;
			}
		}
		
		.fc-border-separate {
			.fc-today {
				background-color: darken(theme-color("light"), 3%);
			}
		}

		.fc-cell-overlay {
			background-color: fade(theme-color("primary"), 50%);
		}
	}

	.fc-button {
		color: $btn-secondary-color;
		background: $btn-secondary-bg;
		border-color: $btn-secondary-border;
		height: initial;
		@include button-size($input-btn-padding-y, $input-btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
		font-size: 1rem;
		box-shadow: none;
		text-shadow: none;

		&:focus,
		&.focus {
			color: $btn-secondary-color;
			background-color: $btn-secondary-bg;
			border-color: $btn-secondary-border;
			box-shadow: none;
			outline: none;

			@include hover {
				border-color: $btn-secondary-border;
				background-color: darken(theme-color("light"), 4%);
			}
		}

		&:hover, &.hover {
			border-color: $btn-secondary-border;
			background-color: darken(theme-color("light"), 4%);
		}

		&:active,
		&.fc-state-active,
		&.active,
		.open > &.dropdown-toggle {
			box-shadow: none;
			border-color: $btn-secondary-border;
			background-color: darken(theme-color("light"), 9%);

			&:hover,
			&:focus,
			&.focus {
				background-color: darken(theme-color("light"), 9%);
				border-color: $btn-secondary-border;
				box-shadow: none;
			}
		}

		&:active,
		&.active,
		.open > &.dropdown-toggle {
			background-image: none;
		}

		&.disabled,
		&[disabled],
		fieldset[disabled] & {
			&,
			&:hover,
			&:focus,
			&.focus,
			&:active,
			&.active {
				background-color: $btn-secondary-bg;
				border-color: $btn-secondary-border;
			}
		}

		&.fc-prev-button,
		&.fc-next-button {
			padding-left: 7px;
			padding-right: 7px;
		}

		.fc-icon {
			position: relative;
			top: -2px;
			line-height: 0.4;
			vertical-align: middle;
			overflow: visible;
			height: auto;

			&:after {
				position: static;
				font-weight: $font-weight-regular;
				font-size: 1.61538rem;
			}
		}
	}

	.fc-state-default {

		&.fc-corner-left {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		&.fc-corner-right {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	.fc-row .fc-content-skeleton td, 
	.fc-row .fc-helper-skeleton td {
		border-color: inherit;
	}

	.fc-unthemed .fc-today {
		background-color: lighten(theme-color("primary"), 50%);
	}

	@media phone {
		max-width: 767px;

		.fc-toolbar {
			
			.fc-left, .fc-center, .fc-right {
				text-align: center;
				display: block;
				width: 100%;
			}

			.fc-left {
				padding-bottom: 20px;
			}
	
			.fc-center {
			  padding: 0;
			}
		}
	}
}

//External events panel
.fullcalendar-external-events {

	.fc-event {
		margin: 2px 0;
		padding: 5px;
		background: theme-color("primary");
		color: theme-color("light");
		font-size: .923077rem;
		cursor: pointerr;
		display: inline-block;
		border-width: 0;
		border-radius: 0;
	}

	p {
		input {
			margin-top: 15px;
		}

		label {
			font-weight: 300;
			vertical-align: middle;
		}
	}
}