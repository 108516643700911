// Alert color Background
@mixin bg-alert-variation($color) {
	background-color: adjust-color($color, $lightness: 25%, $saturation: -10%);
	color: darken($color, 21%);

	> .icon {
		color: darken($color, 21%);
	}
}

// Saturation color Background
@mixin bg-saturate-alert-variation($color) {
	background-color: adjust-color($color, $lightness: 35%, $saturation: -10%);
}

// Contrast alerts Mixin
@mixin contrast-alert-variation($color){
	background-color: darken($color, 2%);

	> .icon{
		background-color: $color;
	}
}

@mixin theme-color-variation($color) {
	background-color: $color;
}

// Dismissible alerts color variations
@mixin dismissible-variation($color) {

	.close{ 
		color: darken($color, 21%);

		&:hover {
			color: darken($color, 30%);
		}
	}
}

// Alert color icon background
@mixin icon-colored-variation($color) {

	> .icon{
		color: $color;
	}
}