/*------------------------------------------------------------------
  [Tile widgets]
*/

.widget.widget-tile{
	padding: $widget-tile-padding;
	margin-bottom: $widget-margin-bottom;
	display: table;
	table-layout: fixed;
	width: 100%;

	.data-info{
		display: table-cell;
		text-align: right;

		.value{
			font-size: $widget-tile-value-font-size;
			font-weight: $font-weight-light;
		}

		.indicator{
			font-size: $widget-tile-indicator-font-size;
			margin-right: 7px;
			line-height: 24px;
			vertical-align: middle;

			&-equal{
				color: theme-color("info");
			}

			&-positive{
				color: theme-color("success");
			}

			&-negative{
				color: theme-color("danger");
			}
		}

		.desc{
			font-size: $widget-tile-description-font-size;
			line-height: 1;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.chart{
		width: 85px;
		min-height: 45px;
		padding: $widget-tile-chart-padding;
		display: table-cell;
		vertical-align: middle;
	}
}

.widget-tile-wide{
	display: table;
	padding: $widget-tile-wide-padding;
	width: 100%;
	
	> div{
		display: table-cell;
		width: 50%;
		vertical-align: middle;
	}

	.icon{
		display: table-cell;
		font-size: $widget-tile-wide-icon-font-size;
		color: theme-color("primary");
		line-height: 1;
		vertical-align: middle;
		margin-right: 5px;
	}

	.data-info{
		display: table-cell;
		vertical-align: middle;
		padding-left: 1rem;

		.title{
			font-size: $widget-tile-wide-title-font-size;
		}

		.desc{
			color: darken(theme-color("light"), 50%);
			font-size: $widget-tile-wide-description-font-size;
		}
	}

	.tile-value{
		text-align: right;

		> span{
			display: block;
			padding-right: 10px;
			font-size: $widget-tile-wide-value-font-size;
			font-weight: $font-weight-light;
		}
	}
}