/*------------------------------------------------------------------
  [Tables]
*/
.table {

	> thead {
		
		> tr {

			> th {
				border-bottom-width: 2px;
				border-top-width: 0;
				font-weight: $font-weight-regular;
			}
		}

		//Color classes
		&.primary {

			> tr {
				> th {
					color: theme-color("primary");
				}
			}
		}

		&.success {

			> tr {
				> th {
					color: theme-color("success");
				}
			}
		}
	}

	> tbody {

		> tr {
			> td {
				font-weight: $font-weight-regular;
				vertical-align: middle;
			}
		}
	}
}

// .table-sm {

// 	> thead {

// 		> tr {

// 			> th {
// 				padding-top: 10px;
// 				padding-bottom: 8px;
// 			}
// 		}
// 	}
// }

.table-bordered {
	
	> thead {

		> tr {

			> th {
				padding: 13px 10px;
				border-bottom-width: 1px;
			}
		}
	}

	tbody {

		>tr {

			td {

				&:first-child {
					padding-left: 10px;
				}

				&:last-child {
					padding-right: 10px;
				} 
			}
		}
	}
}

// Table backgrounds

// Generate the contextual variants
@include table-row-variant(primary, theme-color("primary"));
@include table-row-variant(success, theme-color("success"));
@include table-row-variant(info, theme-color("info"));
@include table-row-variant(warning, theme-color("warning"));
@include table-row-variant(danger, theme-color("danger"));
@include table-row-variant(dark, theme-color("noir"));

.table-responsive {
	border: 0;
	margin-bottom: 0;

	tbody {

		> tr {

			> td {
				padding-top: $table-condensed-cell-padding + .307692rem;
				padding-bottom: $table-condensed-cell-padding + .307692rem;
			}
		}
	}
}

//Helpers classes
.table {

	tr {
		
		th, td {

			.custom-control {
				padding: 0;
				margin: 0;
				vertical-align: middle;
			}

			//Actions column
			&.actions {
				text-align: center;

				.icon {
					color: $body-color;
					font-size: 1.38462rem;
					line-height: 0.8;
					vertical-align: middle;

					&:hover {
						color: darken(theme-color("light"), 50%);
					}
				}
			}
		}
	}

	tbody {

		tr {
	
			td {

				&.user-avatar {

					img {
						height: 30px;
						width: 30px;
						border-radius: 50%;
						margin-right: 10px;

						@include phone {
							display: none;
						}
					}
				}

				&.cell-detail {

					span {
						display: block;
					}

					.cell-detail-description {
						display: block;
						font-size: .846154rem;
						color: darken(theme-color("light"), 40%);
					}
				}

				&.user-info {

					img {
						float: left;
					}

					span {
						padding-left: 40px;
					}
				}

				&.milestone {
					min-width: 100px;

					span {
						display: block;
						font-size: 0.846154rem;
						color: darken(theme-color("light"), 40%);
						margin-bottom: 0.461538rem;

						&.completed {
							float: right;
						}
					}

					.progress {
						height: 6px;
						background-color: darken(theme-color("light"), 10%);
						margin-bottom: 0;

						.progress-bar {
							height: 6px;
							border-radius: $border-radius;
						}
					}
				}
			}

			&.online {

				td:first-child {
					border-left: 3px solid theme-color("primary");
					padding-left: 17px;
				}
			}
		}
	}

	.number {
		text-align: right;
	}

	&.table-borderless {

		tbody {

			tr {

				td {
					border-top-width: 0;
				}
			}
		}
	}
}	

//Table inside full-width widget
.panel-table {
	table {
			margin-bottom: 0;
		}
	
	tr {

		th, td {

			&:first-child {
				padding-left: 20px;
			}

			&:last-child {
				padding-right: 20px;
			}
		}
	}

	thead {

		tr {

			th {
				padding-top: 15px;
				padding-bottom: 10px;
			}
		}
	}
}