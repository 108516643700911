.week-chart {

	&.widget.widget-fullwidth {		

		.widget-head {

			 & {
				margin-bottom: 3rem;
			}
		}
	}
}